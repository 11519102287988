.DeliveryHeaderBlock {
  position: sticky;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 0;
  padding: 15px 10px 5px 10px;
  background: rgb(245, 245, 245);
}

.blockFooter {
  padding-top: 11px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.DeliveryBlockBtn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.PriceDataTd {
  font-size: 12px;
  padding: 8px 10px;
  text-align: center;
}

.PriceDataTd:nth-child(2) {
  width: max-content;
}
.PriceDataTd:nth-child(3) {
  text-align: center;
  width: 115px;
}
.PriceDataTd:nth-child(4) {
  text-align: center;
  width: 60px;
}
.PriceDataTd:nth-child(5) {
  text-align: center;
  width: 27px;
}

.ClientAddInp {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.ClientAddInp:hover {
  border-color: #4096ff !important;
  border-inline-end-width: 1px !important;
}

.ReturnLoding {
  margin: 0 !important;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  background: rgba(214, 214, 214, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px !important;
}

.balanseProductList {
  background-color: #fff;
}
/* transfereed goods */
.transferredGoodsWrapper {
  background-color: var(--adm-color-background);
  -webkit-box-shadow: 0px 0px 12px -1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 12px -1px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 12px -1px rgba(34, 60, 80, 0.2);
  padding: 10px;
  margin: 0 0 10px 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.transferredGoodsHeader {
  padding-bottom: 15px;
}

.transferredGoodsHeaderTitle {
  font-size: 16px;
  font-weight: 700;
  color: #4e4e4e;
}
.transferredGoodsTable {
  border-collapse: collapse;
}

.transferredGoodsCaption {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
  color: #4e4e4e;
}
.transferredGoodsTRowHead {
  height: 22px;
}
.transferredGoodsTBodyTRow {
  height: 30px;
}
.transferredGoodsTBodyTRow:nth-child(odd) {
  /* background-color: var(--adm-color-success); */
  background-color: var(--adm-color-box);
  color: #4e4e4e;
}
.transferredGoodsTdNumber {
  padding-left: 5px;
}
.transferredGoodsTd {
  color: #4e4e4e;
  text-align: end;
  font-size: 18px;
  padding-right: 5px;
}
.transferredGoodsFooter {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
}
