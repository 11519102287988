.content {
  min-height: 50px;
  padding-bottom: 15px;
}

.footer {
  padding-top: 11px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
}

.customBody {
  /* color: var(--adm-color-success); */
  margin: 0 5px;
}

/* .PriceDataTr {
  margin: 20px 0;
  border-bottom: 1px solid #a3a3a3;
} */

.OrdersBlockList {
  padding: 0px 15px 10px 15px;
}
.OrdersCardWrapper {
  border-radius: 16px;
  box-shadow: var(--adm-color-light) 0px 0px 12px 0px, 0px 0px 5px 0px #fff;
  margin: 0 0 20px 0;
}
.OrderItemAddContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.OrdersItemPost {
  display: flex;
  justify-content: flex-end;
  padding: 0 10px 0 10px;
}
.OrdersTableBlock {
  flex: auto;
  overflow-y: scroll;
  padding: 0 0 50px 0;
}
.PriceDataTd {
  font-size: 12px;
  padding: 8px 10px;
}

.PriceDataTd:nth-child(2) {
  width: max-content;
}
.PriceDataTd:nth-child(3) {
  text-align: center;
  width: 115px;
}
.PriceDataTd:nth-child(4) {
  text-align: center;
  width: 60px;
}
.PriceDataTd:nth-child(5) {
  text-align: center;
  width: 27px;
}

.PriceDataHeader {
  height: 530px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.PriceDataBody {
  height: 100%;
  background: #fff;
  padding: 0;
  margin: 0;
}

/* OrdersAdd page */
.BlockAddOrders {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0;
}

/* Block table price */
.BlockPriceLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00b578;
}

/* Таблица заказа */

.OrdersModalBlock {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 15px 0 0 0;
}

.DataOrderTr {
  border-bottom: 1px solid #00b578;
}
/* .DataOrderTr:nth-child(odd) {
  background-color: #663613;
} */
.PriceDataTr {
  height: max-content;
  border-bottom: 1px solid rgb(222, 222, 222);
}

.container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.container__headers {
  display: flex;
  justify-content: space-between;
}
.container__headers__tag {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.OrdersNewBlockBtn {
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
}
.scrollPart {
  flex: auto;
  overflow-y: scroll;
}

.ClientAddInp {
  width: 100% !important;
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  line-height: 1.6;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  background-color: #ffffff;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
}

.ClientAddInp:hover {
  border-color: #4096ff !important;
  border-inline-end-width: 1px !important;
}

.customCardBody {
  box-shadow: #00b578 !important;
}

.DeliveryListTag {
  margin-top: 15px;
  width: inherit;
  display: flex;
  justify-content: space-between;
}
