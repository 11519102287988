.OrdersDetailedTable {
  width: 100%;
  border-collapse: collapse;
}
.OrdersDetailedTable thead tr th {
  background: #f4f4f4 !important;
}
.OrdersDetailedTablePrint {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
}
.OrdersDetailedTr {
  border-bottom: 1px solid rgb(181, 181, 181) !important;
}
.OrdersDetailedTr:nth-child(even) {
  background: #f4f4f4 !important;
}

.OrdersDetailedTd {
  text-align: center;
  padding: 10px 0;
}
.OrdersDetailedTd:nth-child(1) {
  width: 50px;
}

/* confirm page */
.BlockAmallarConfirms {
  display: flex;
  justify-content: space-around;
}

.BlockTableBrint {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
}

@media print {
  .OrdersDetailedTable thead tr th:nth-child(6) {
    display: none;
  }
  .OrdersDetailedTd:nth-child(6) {
    display: none;
  }
}

.searchInp {
  width: 90%;
  padding: 10px 0 10px 5px;
  outline: none;
  border: none;
  margin-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 18px;
  border-radius: 10px;
}
.searchInp:focus {
  border-bottom: 1px solid #ff8f1f;
}
