.top__modal-btn {
  color: #fff;

  border-radius: 5px;
  background: #00b578;
  padding: 5px 10px;
  font-size: 16px !important;
  border: 1px solid #00b578;
}
/* telegrem button color #3476ab */

.top-open-modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.4s all;
  z-index: 1001;
  overflow-y: scroll;
}
.top-open-modal.actives {
  top: 0;
  opacity: 1;
  pointer-events: all;
}
.top-modal-content {
  width: 100vw;
  padding: 20px;
  transition: 0.3s all;
  z-index: 1002;
  opacity: 0;
  position: absolute;
  top: -150%;
}
.top-modal-content.activ {
  opacity: 1;
  top: 0;
}
.top__modal-btn-block {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0 10px 0;
}

/*  */
/* .top__modal-wrapper {
  height: 100vh !important;
  overflow-y: scroll !important;
} */

.top__modal-btn-fixed {
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
}

.top__modal-btn-fixed .top__modal-btn {
  background: none;
  border: none;
}
