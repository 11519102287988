.block__custom-skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  padding-left: 10px;
}
.customSkeleton {
  --width: 95%;
  --height: 30px;
  --border-radius: 5px;
  margin: 15px 0;
}

/* ***** preloader ***** */
.load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*change these sizes to fit into your project*/
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
}
.load hr {
  border: 0;
  margin: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite;
}

.load :first-child {
  background: #19a68c;
  animation-delay: -1.5s;
}
.load :nth-child(2) {
  background: #f63d3a;
  animation-delay: -1s;
}
.load :nth-child(3) {
  background: #fda543;
  animation-delay: -0.5s;
}
.load :last-child {
  background: #193b48;
}

@keyframes spin {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(160%);
  }
  50% {
    transform: translate(160%, 160%);
  }
  75% {
    transform: translate(0, 160%);
  }
}
/* end preloder */

/* skeleton */

.block__skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.block__skeleton-header {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  margin: 15px 0 5px 0;
}

.skeleton-header-item:nth-child(1) {
  --width: 40%;
  --height: 25px;
  --border-radius: 5px;
}
.skeleton-header-item:nth-child(2) {
  --width: 20%;
  --height: 20px;
  --border-radius: 5px;
}
.skeleton-header-item:nth-child(3) {
  --width: 30%;
  --height: 20px;
  --border-radius: 5px;
}
.block__skeleton-content {
  padding: 4px 10px !important;
  margin-top: 25px;
}
.skeleton-content-item {
  --width: 100%;
  --height: 15px;
  --border-radius: 5px;
  margin: 5px 0 !important;
}
.block__skeleton-footer {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}
.skeleton-footer-items:nth-child(1) {
  --width: 25%;
  --height: 30px;
  --border-radius: 5px;
}
.skeleton-footer-items:nth-child(2) {
  --width: 33%;
  --height: 30px;
  --border-radius: 5px;
}
