.blocklogin {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center !important;
  margin-top: 150px;
}
.password {
  display: flex;
  align-items: center;
}

.input {
  flex: auto;
}

.eye {
  flex: none;
  margin-left: 8px;
  padding: 4px;
  cursor: pointer;
}
.svg {
  display: block;
  font-size: var(--adm-font-size-7);
}
