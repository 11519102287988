.oreders__change .adm-list-item-content .adm-list-item-content-main {
  width: 100% !important;
  display: flex !important ;
  justify-content: space-between !important;
}
/* .oreders__change
  .adm-list-item-content
  .adm-list-item-content-main
  .adm-form-item-label {
  width: 60vw;
} */
