.workersAddBlock {
  /* position: fixed;
  top: 0;
  z-index: 10; */
  padding-top: 5px;
  padding-bottom: 5px;
  padding: 5px 15px;
}
.content {
  width: 80vw;
  height: 50px;
  margin: 0 20px !important;
}

.footer {
  padding-top: 11px;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
}

.customBody {
  color: var(--adm-color-success);
}

/* .keyboardNumber {
  position: fixed;
  bottom: -100%;
} */
