@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");
a {
  text-decoration: none;
  color: #fff;
}
* {
  font-family: "Inter", sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  text-align: center;
}

.adm-infinite-scroll {
  display: none !important;
}

.adm-result-page-bg {
  background-color: var(--adm-color-success) !important;
}
.adm-result-page-detail-bold {
  font-size: 18px !important;
}

/* add new client label */
.ant-form-item-label {
  padding-bottom: 3px !important;
  margin: 0 !important;
}
.ant-form-item {
  margin: 0 10px 2px 10px !important;
}
.form-control {
  width: 100% !important;
}

.ant-form-item-label {
  height: 25px;
  padding: 0;
}

/* Стиль для OrdersChange  form */
.oreders-change.adm-list-item {
  padding-left: 10px !important;
}
.oreders-change.adm-list-item-content {
  display: flex !important;
  justify-content: space-between !important;
  padding-right: 20px !important;
}
.oreders-change.adm-list-item-content-prefix {
  width: 60vw !important;
}
.adm-list-item-content-main {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  gap: 15px !important;
}
.adm-list-item-title {
  width: 100% !important;
}
.adm-form-item-child-position-normal {
  width: 100%;
}
/* menu */
.adm-tab-bar {
  background-color: #00b578;
}
.adm-tab-bar-item-icon {
  font-size: 22px;
}
.adm-tab-bar-item {
  color: #fff !important;
}
.adm-tab-bar-item-title .adm-tab-bar-item-title-with-icon {
  font-size: 16px;
}

.editable-row {
  font-size: 14px !important;
}

/*  */
.debtors__single {
  font-size: 12px;
}
.adm-image {
  -o-border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  border-radius: 10px;
}
.ant-table-cell {
  font-size: 12px !important;
}

.adm-tab-bar-item-active {
  color: #00b578 !important;
  padding: 0 !important;
}
.adm-tab-bar-item-active .adm-badge-wrappepxr {
  width: 100%;
  text-align: center !important;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50px !important;
}
.adm-tab-bar-item-active .adm-tab-bar-item-icon {
  -o-border-radius: 50%;
  background-color: #fff !important;
  color: #00b578;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 9px;
}
