.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  /* background-color: #f9f9f9; */
}

/* .top {
  flex: 0;
  border-bottom: solid 1px var(--adm-color-border);
} */

.body {
  flex: auto;
  justify-content: center;
  overflow-y: scroll;
}

.bottom {
  width: 100%;
  /* position: fixed;
  bottom: 0; */
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
  background: #fff;
}
